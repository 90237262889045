.erro-404{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.erro-404 h1{
    color: #FFFFFF;
    font-size: 32px;
}

.erro-404 button{
    cursor: pointer;
    font-size: 16px;
    transition: all 200ms ease-in-out;
    color: #8C8C8C;
}

.erro-404 button:hover{
    animation: none;
}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1024px) {
    
}

@media only screen and (max-width: 768px) {
    .erro-404 .lottie{
        width: 100% !important;
        height: auto !important;
    }
}