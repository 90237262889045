body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.briefing-form iframe{
    border-radius: 0 !important;
    height: 100vh !important;
    margin-bottom: -4px;
}
@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-Regular.f0f84558.otf) format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-Bold.482e3726.otf) format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-ThinItalic.1030ebab.otf) format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Mont';
    src: url(/static/media/Mont-BoldItalic.fb896fe6.otf) format('opentype');
    font-weight: bold;
    font-style: italic;
}

a{
    text-decoration: none;
}

button{
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.06);
    -webkit-backdrop-filter: blur(32px);
            backdrop-filter: blur(32px);
    border: none;
    color: #FFF;
}

button:hover{
    background: rgba(255, 255, 255, 0.12);
    animation: pulse 1s;
}

*{
    font-family: Mont;
    box-sizing: border-box;
}

#root, #root > div{
    height: 100%;
}

.info button{
    cursor: pointer;
}

.background{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.container-page{
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bg-glow{
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}
.background .lottie-right{
    position: absolute;
    right: -120px;
    top: -100px;
}

.background .lottie-left{
    position: absolute;
    left: -120px;
    bottom: -500px;
}

body{
    background-color: #121212;
    height: 100dvh;
    overflow: hidden;
}

header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 16px;
}

.container-swiper {
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height:  600px;
    border-radius: 16px;
    border: 6px solid #1F1F1F;
}
.swiper{
    width: 100%;
    height: 100%;
    border-radius: 16px;
    aspect-ratio: 16/12;
}

.footer-swiper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}

.buttons-prev-next{
    display: flex;
    grid-gap: 6px;
    gap: 6px;
}

#tags{
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    justify-content: center;
}

.container-tags{
    display: flex;
    grid-gap: 16px;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

#tags span{
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.06);
    -webkit-backdrop-filter: blur(32px);
            backdrop-filter: blur(32px);
    padding: 10px;
    font-weight: bold;
}

.buttons-prev-next button{
    cursor: pointer;
}

.hero{
    display: flex;
    justify-content: center;
}

.container-content-hero {
    display: flex;
    align-items: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    padding: 16px;
    grid-gap: 32px;
    gap: 32px;
    max-width: 1500px;
}

.container-content-hero h1{
    font-size: 48px;
    font-weight: bold;
    color: #fff;
}

.container-content-hero span{
    color: #969696;
}

.container-content-hero .highlight{
    color: #FF3773;
}

section.body{
    display: flex;
    justify-content: center;
}

section.body .content{
    max-width: 1500px;
    color: #969696;
    padding: 0 32px;
    padding-bottom: 56px;
}

section.body .content a{
    color: #ffffff;
}

footer{
    background: #0D0D0D;
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
}

footer .content{
    width: 100%;
    max-width: 1500px;
    display: flex;
    justify-content: space-between;
}

footer a{
    color: #ffffff32;
    text-decoration: none;
    transition: all 200ms ease-in-out;
}

footer a:hover{
    color: #ffffff;
}

footer .links{
    display: flex;
    grid-gap: 16px;
    gap: 16px;
}

html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.hidden,
[hidden] {
    display: none !important;
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    /* 1 */
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix::before,
.clearfix::after {
    content: " ";
    display: table;
}

.clearfix::after {
    clear: both;
}

.topbar{
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 1500px;
}

.topbar img{
    height: 48px;
}

.topbar-menus{
    display: flex;
    grid-gap: 12px;
    gap: 12px;
}

.topbar-menus button{
    cursor: pointer;
}

.topbar-menus img{
    height: 24px;
}

.info button{
    background: #FFF;
    color: #000;
    padding: 14px 24px;
}

.swiper-slide__img {
    width: 100%;
    height: 100%;
}

.swiper-slide__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
}

@media only screen and (min-width: 35em) {
    /* Style adjustments for viewports that meet the condition */
}

@media print,
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 1.25dppx),
(min-resolution: 120dpi) {
    /* Style adjustments for high resolution devices */
}

@media print {
    *,
    *::before,
    *::after {
        background: #fff !important;
        color: #000 !important;
        /* Black prints faster */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]::after {
        content: " (" attr(href) ")";
    }

    abbr[title]::after {
        content: " (" attr(title) ")";
    }

    a[href^="#"]::after,
    a[href^="javascript:"]::after {
        content: "";
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes slideRightToLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideLeftToRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

@media only screen and (max-width: 1300px) {
    .container-content-hero{
        flex-direction: column;
    }
}

@media only screen and (max-width: 1024px) {
    footer .content{
        flex-direction: column;
    }
}

@media only screen and (max-width: 768px) {
    .bg-glow img{
        max-height: 100vh;
    }
    .container-content-hero{
        flex-direction: column;
        grid-gap: 72px;
        gap: 72px;
    }
    .background .lottie-right{
        right: -450px;
    }
    .background .lottie-left{
        left: -200px;
    }
    .container-swiper{
        max-width: calc(100dvw - 32px);
    }
    .topbar-menus{
        display: none;
    }
    .info h1{
        font-size: 32px;
    }
    .hero{
        overflow: unset;
    }
    .buttons-prev-next{
        display: none;
    }
    .footer-swiper{
        justify-content: center;
    }
    #tags{
        display: flex;
        grid-gap: 16px;
        gap: 16px;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    footer{
        flex-direction: column-reverse;
    }
    footer .links{
        width: 100%;
        flex-direction: column;
        align-content: start;
    }
    .container-content-hero{
        padding-bottom: 64px;
    }
    .background{
        overflow: hidden;
    }

    .container-tags{
        flex-wrap: wrap;
    }
}
body{
    background-color: #1E1E1E;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.erro-404{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.erro-404 h1{
    color: #FFFFFF;
    font-size: 32px;
}

.erro-404 button{
    cursor: pointer;
    font-size: 16px;
    transition: all 200ms ease-in-out;
    color: #8C8C8C;
}

.erro-404 button:hover{
    animation: none;
}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1024px) {
    
}

@media only screen and (max-width: 768px) {
    .erro-404 .lottie{
        width: 100% !important;
        height: auto !important;
    }
}
